
















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Fab from '@/components/Fab.vue';
import { Users, UserAddModal } from '@/components/settings';

@Component({
  components: {
    Fab,
    Users,
    UserAddModal,
  },
})
export default class UsersSettingsPage extends Vue {
  loading: boolean = false;
  showModal: boolean = false;

  $refs: {
    users: Users;
  };

  onCreated(): void {
    this.$refs.users.refresh();
  }
}
